import { combineReducers } from 'redux'

import { SimuladorState } from './simulador/types'
import { FontePagamentoState } from './fontePagamento/types'
import { SituacaoFuncionalState } from './situacaoFuncional/types'
import { DadosPessoaisState } from './dadosPessoais/types'
import { CodIndicacaoContatoState } from './codIndicacaoContato/types'
import { SimulacaoState } from './simulacao/types'
import { AlertaState } from './alerta/types'
import { ValidaState } from './valida/types'
import { DadosProfissionaisState } from './dadosProfissionais/types'
import simulador from './simulador'
import fontePagamento from './fontePagamento'
import situacaoFuncional from './situacaoFuncional'
import dadosPessoais from './dadosPessoais'
import codIndicacaoContato from './codIndicacaoContato'
import simulacao from './simulacao'
import alerta from './alerta'
import valida from './valida'
import dadosProfissionais from './dadosProfissionais'
import { ConsultaMargemCipState } from './consultasMargem/consultaMargemCip/types'
import consultaMargemCip from './consultasMargem/consultaMargemCip'
import { ConsultaMargemGeralState } from './consultasMargem/consultaMargemGeral/types'
import consultaMargemGeral from './consultasMargem/consultaMargemGeral'
import { SiapeAutorizacaoAnuenciaState } from './consultasMargem/consultaMargemSiape/types'
import siapeAutorizarAnuencia from './consultasMargem/consultaMargemSiape'
import { PendenciaState } from './pendencia/types'
import pendencia from './pendencia'
import { BeneficiarioInssState } from './consultasMargem/consultaBeneficiarioInss/types'
import { ConsultaExercitoState } from './consultasMargem/consultaMargemExercito/types'
import beneficiarioInss from './consultasMargem/consultaBeneficiarioInss'
import consultaMargemExercito from './consultasMargem/consultaMargemExercito'
import consultaMargemRio from './consultasMargem/consultaMargemRio'
import { ConsultaMargemRioState } from './consultasMargem/consultaMargemRio/types'
import { AtendimentoState } from './atendimento/types'
import atendimento from './atendimento'
import indiqueGanhe from './indiqueGanhe'
import { IndiqueGanheState } from './indiqueGanhe/types'

export interface NovoState {
  simulador: SimuladorState
  fontePagamento: FontePagamentoState
  situacaoFuncional: SituacaoFuncionalState
  dadosPessoais: DadosPessoaisState
  codIndicacaoContato: CodIndicacaoContatoState
  simulacao: SimulacaoState
  alerta: AlertaState
  valida: ValidaState
  dadosProfissionais: DadosProfissionaisState
  consultaMargemCip: ConsultaMargemCipState
  consultaMargemGeral: ConsultaMargemGeralState
  siapeAutorizarAnuencia: SiapeAutorizacaoAnuenciaState
  beneficiarioInss: BeneficiarioInssState
  consultaMargemExercito: ConsultaExercitoState
  consultaMargemRio: ConsultaMargemRioState
  pendencia: PendenciaState
  atendimento: AtendimentoState
  indiqueGanhe: IndiqueGanheState
}

export default combineReducers({
  simulador,
  fontePagamento,
  situacaoFuncional,
  dadosPessoais,
  codIndicacaoContato,
  simulacao,
  alerta,
  valida,
  dadosProfissionais,
  consultaMargemCip,
  consultaMargemGeral,
  siapeAutorizarAnuencia,
  pendencia,
  beneficiarioInss,
  consultaMargemExercito,
  consultaMargemRio,
  atendimento,
  indiqueGanhe,
})
