import { BaseServices, HttpMethod, HttpStatus } from '@services/base'

export default (data: ReferirIndiqueGanheRequest) => {
  const pathname = process.env.REACT_APP_INSS_REFER_AND_EARN
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<ReferirIndiqueGanheResponse>({
    pathname,
    method,
    host,
    data,
  })

  return response
}

export interface ReferirIndiqueGanheRequest {
  referralCode: string
  cpf?: string
}

export interface ReferirIndiqueGanheResponse {
  result: HttpStatus
}
