import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { ApplicationState } from '@store/types'
import { showError } from '@store/ui/error/actions'

import { IndiqueGanheError, IndiqueGanheSuccess, setLoadingIndiqueGanhe } from '../actions'
import { StartPayload } from '../types'

export default function* postCodigoIndicacaoSaga(action: AnyAction) {
  const { cpf } = yield select((state: ApplicationState) => state.novo.dadosPessoais)
  const socialId = cpf
  const payload = action.payload as StartPayload
  const referralCode = yield* select(
    (state: ApplicationState) => state.novo.codIndicacaoContato.codigoIndicacao,
  )
  const { isApp } = yield* select((state: ApplicationState) => state.ui.navigation)
  const data = isApp ? { referralCode } : { referralCode, socialId }
  try {
    yield put(setLoadingIndiqueGanhe(true))
    const { result } = yield services.postReferirIndiqueGanhe(data)
    yield put(IndiqueGanheSuccess(result))
    yield put(setLoadingIndiqueGanhe(false))
  } catch (error) {
    if (payload.callbackError) {
      yield put(
        showError({
          title: 'Erro',
          message: 'Ocorreu um erro ao validar o código',
          actionTitle: 'Tentar Novamente',
          actionCallback: () => IndiqueGanheError('Ocorreu um erro ao validar o código'),
        }),
      )
    }
    yield put(setLoadingIndiqueGanhe(false))
  }
}
