import React, { Suspense, lazy } from 'react'

import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import FallbackPage from '@components/organims/FallbackPage'
import { caching } from '@utils/functions'
import { ApplicationState } from '@store/types'
import Bloqueado from '@components/pages/Privado/Bloqueado'

import routes from './routes'

const Onboarding = lazy(() => import('@components/pages/Privado/Onboarding'))
const DadosPessoais = lazy(() => import('@components/pages/Privado/DadosPessoais'))
const ResumoEmprestimo = lazy(() => import('@components/pages/Privado/ResumoEmprestimo'))

const Contato = lazy(() => import('@components/pages/Privado/Contato'))
const Token = lazy(() => import('@components/pages/Privado/Token'))
const NaoLiberado = lazy(() => import('@components/pages/Privado/NaoLiberado'))
const DadosRecebidos = lazy(() => import('@components/pages/Privado/DadosRecebidos'))
const RootPage = lazy(() => import('@components/pages/Privado/RootPage'))
const DadosProfissionais = lazy(() => import('@components/pages/Privado/DadosProfissionais'))
const VerificarValorSimulado = lazy(
  () => import('@components/pages/Privado/VerificarValorSimulado'),
)
const EmpresaNaoLiberada = lazy(() => import('@components/pages/Privado/EmpresaNaoLiberada'))
const Routes: React.FC = () => {
  const sessionStore = useSelector((state: ApplicationState) => state.session)
  caching(sessionStore)

  return (
    <Switch>
      <Suspense fallback={<FallbackPage />}>
        <Route exact path={routes.ONBOARDING} component={Onboarding} />
        <Route exact path={routes.DADOS_PESSOAIS} component={DadosPessoais} />
        <Route exact path={routes.CONTATO} component={Contato} />
        <Route exact path={routes.TOKEN} component={Token} />
        <Route exact path={routes.RESUMO_EMPRESTIMO} component={ResumoEmprestimo} />
        <Route exact path={routes.NAO_LIBERADO} component={NaoLiberado} />
        <Route exact path={routes.DADOS_RECEBIDOS} component={DadosRecebidos} />
        <Route exact path={routes.ROOT} component={RootPage} />
        <Route exact path={routes.DADOS_PROFISSIONAIS} component={DadosProfissionais} />
        <Route exact path={routes.VERIFICAR_VALOR_SIMULADO} component={VerificarValorSimulado} />
        <Route exact path={routes.EMPRESA_NAO_LIBERADA} component={EmpresaNaoLiberada} />
        <Route exact path={routes.BLOQUEADO} component={Bloqueado} />
      </Suspense>
    </Switch>
  )
}
export default Routes
