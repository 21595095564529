enum routes {
  AUTORIZACAO = '/novo/autorizacao',
  EMPRESA_NAO_LIBERADA = '/novo/empresa-nao-liberada',
  VERIFICAR_VALOR_SIMULADO = '/novo/verificar-valor',
  DADOS_PROFISSIONAIS = '/novo/dados-profissionais',
  DADOS_RECEBIDOS = '/novo/dados-recebidos',
  NAO_LIBERADO = '/novo/nao-liberado',
  TOKEN = '/novo/token',
  CONTATO = '/novo/contato',
  DADOS_PESSOAIS = '/novo/dados-pessoais',
  ONBOARDING = '/novo/onboarding',
  RESUMO_EMPRESTIMO = '/novo/resumo-emprestimo',
  ROOT = '/novo',
  BLOQUEADO = '/novo/bloqueado',
}
export default routes
