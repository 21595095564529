import { ConveniosCodes } from '@utils/enums'
import { SessionState } from '@store/session/types'
import { BridgeService } from '@services/bridge'
import { Simulacao } from '@store/novo/simulacao/types'

import packageJson from '../../../package.json'

export const getHoraBaseINSS = (horaAtual: string) =>
  Number(horaAtual) >= 6 && Number(horaAtual) <= 21

export const validaEnvHomeByBridge = (isApp: boolean) => {
  if (isApp) {
    return `${process.env.REACT_APP_HOME_EMPRESTIMO}`
  }
  return process.env.REACT_APP_HOME_SIMULADOR_NOVO
}

export const isConveniosCip = (convenioSelecionado: string | number | undefined) =>
  convenioSelecionado === ConveniosCodes.ESTADO_MT ||
  convenioSelecionado === ConveniosCodes.ESTADO_SP ||
  convenioSelecionado === ConveniosCodes.PREF_SP

export const idadePelaDataNascimento = (dataNascimento: string): number => {
  const data = new Date()
  const nascimento = new Date(dataNascimento)
  let idade = data.getFullYear() - nascimento.getFullYear()
  const mes = data.getMonth() - nascimento.getMonth()

  if (mes < 0 || (mes === 0 && data.getDate() < nascimento.getDate())) {
    idade -= 1
  }

  return idade
}

export const caching = (sessionStore: SessionState) => {
  const version = localStorage.getItem('version')
  const novoLocalStorage = localStorage.getItem('persist:novo')
  if (
    BridgeService.isBrowser() &&
    ((version && version !== packageJson.version) ||
      (novoLocalStorage && version !== packageJson.version))
  ) {
    window.location.href = `${process.env.REACT_APP_HOME_SIMULADOR_NOVO_REDIRECT}${sessionStore.urlParams}`
    localStorage.setItem('version', packageJson.version)
  } else {
    localStorage.setItem('version', packageJson.version)
  }
}

export const openLinkByEnviroment = async (url: string, OpenNewTab?: boolean) => {
  if (BridgeService.isBrowser()) {
    if (OpenNewTab) {
      window.open(url, '_blank')?.focus()
    } else {
      window.location.href = url
    }
  } else {
    await BridgeService.openDeepLink(url)
  }
}

export const getParcelaAtualSimulacoes = (simulacoes: Simulacao[], qtdeParcela?: number) => {
  let parcelaReturn: Simulacao

  if (!qtdeParcela) {
    parcelaReturn = simulacoes?.reduce(
      (simulacaoAccumulator: Simulacao, simulacaoCurrent: Simulacao) => {
        if (simulacaoAccumulator.qtdeParcelas < simulacaoCurrent.qtdeParcelas) {
          return simulacaoCurrent
        }
        return simulacaoAccumulator
      },
    )
  } else {
    parcelaReturn = simulacoes?.find((simulacao) => simulacao.qtdeParcelas === qtdeParcela) || {
      qtdeParcelas: 0,
      valorSolicitado: 0,
      valorParcela: 0,
      valorLiberado: 0,
      valorIOF: 0,
      valorFinanciado: 0,
      cETAm: 0,
      taxaNominalAm: 0,
      dataUltimoVencimento: '',
      dataPrimeiroVencimento: '',
      tIRAm: 0,
      tIRAa: 0,
      cETAa: 0,
      taxaNomialAa: 0,
      jurosContrados: 0,
      jurosContradosPercentual: 0,
      valorTotalASerPago: 0,
      tarifaCadastroPercentual: 0,
      tarifaCadastro: 0,
      valorIOFPercentual: 0,
      valorSeguro: 0,
      valorSeguroPercentual: 0,
    }
  }
  return parcelaReturn
}
