import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { isIOS } from 'react-device-detect'

import NovoPage from '@components/templates/NovoPage'
import { ApplicationState } from '@store/types'
import Tag from '@utils/Tags'
import { StepNRNovo } from '@utils/enums'
import { trackingSimulacao } from '@store/novo/simulacao/actions'
import { Button } from '@interco/inter-ui/components/Button'
import GridStyled from '@atoms/GridStyled'
import { Timer } from '@assets/icons'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import { Colors } from '@utils/Colors'
import { openLinkByEnviroment } from '@utils/functions'

const Bloqueado = () => {
  const dispatch = useDispatch()
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { valorTotal, parcelas } = useSelector((state: ApplicationState) => state.novo.simulador)
  const { convenios, fonteSelecionada, convenioSelecionado } = useSelector(
    (state: ApplicationState) => state.novo.fontePagamento,
  )
  const { fontesPagamento } = useSelector((state: ApplicationState) => state.globalApi)

  const { sangueLaranja } = useSelector((state: ApplicationState) => state.novo.dadosPessoais)

  useEffect(() => {
    dispatch(trackingSimulacao(StepNRNovo.BLOQUEADO))
    if (sangueLaranja) {
      Tag.simuladorConsignadoTag({
        tela: 'Tela de Sucesso - Consignado',
        item_name: 'Consignado Sangue Laranja',
        product: 'CONSIG_SANGUELARANJA_SIMULACAO',
        name: 'SANGUE_LARANJA_FLUXO',
        ref_type: 'Feedback',
        content_action: 'Dado carregado',
      })
    }
  }, [dispatch, sangueLaranja])

  useEffect(() => {
    Tag.simuladorConsignadoTag({
      ref_figma: '3',
      ref_type: 'Sucesso',
      flow: convenios
        ?.find((convenio) => convenio.codigo === Number(convenioSelecionado))
        ?.descricao.toUpperCase(),
      content_name: 'Bloqueado',
      tela: 'Tela de Sucesso - Consignado',
      content_action: 'Dado carregado',
      content_grupo:
        fontesPagamento?.find((fonte) => fonte.codigo === Number(fonteSelecionada))?.descricao ||
        fonteSelecionada,
      content_conv:
        convenios?.find((convenio) => convenio.codigo === Number(convenioSelecionado))?.descricao ||
        convenioSelecionado,
    })
    Tag.enviarTagueamentoWeb({
      content_name: 'Voltar ao início',
      tela: 'Não Liberado',
      element_action: 'page_loaded',
      c_page: window.location.href,
      redirect_url: isApp
        ? process.env.REACT_APP_HOME_EMPRESTIMO
        : process.env.REACT_APP_HOME_SIMULADOR_NOVO,
      step: 'error',
    })
  }, [
    convenioSelecionado,
    convenios,
    fonteSelecionada,
    fontesPagamento,
    isApp,
    parcelas,
    valorTotal,
  ])

  return (
    <NovoPage
      id="inss-indisponivel"
      stickyFooter={
        <>
          <Button
            variant="primary"
            onClick={async () => {
              if (sangueLaranja) {
                Tag.simuladorConsignadoTag({
                  tela: 'Bloqueado',
                  item_name: 'Consignado Sangue Laranja',
                  product: 'CONSIG_SANGUELARANJA_SIMULACAO',
                  name: 'SANGUE_LARANJA_FLUXO',
                  content_name: 'Voltar pra Home',
                  ref_type: 'botão',
                  content_action: 'toque',
                })
              } else {
                Tag.enviarTagueamentoWeb({
                  content_name: 'Voltar pra Home',
                  tela: 'Tela de Sucesso - Consignado',
                  element_action: 'page_loaded',
                  c_page: window.location.href,
                  redirect_url: isApp
                    ? process.env.REACT_APP_HOME_EMPRESTIMO
                    : process.env.REACT_APP_HOME_SIMULADOR_NOVO,
                  step: 'erro',
                })
              }
              await openLinkByEnviroment(
                isIOS
                  ? 'https://apps.apple.com/br/app/carteira-de-trabalho-digital/id1295257499'
                  : 'https://play.google.com/store/search?q=carteira+de+trabalho+digital&c=apps&hl=pt_BR',
              )
            }}
          >
            Ir para o App da Carteira Digital
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              Tag.enviarTagueamentoWeb({
                content_name: 'Voltar para home',
                tela: 'Proposta recebida',
                element_action: 'page_loaded',
                c_page: window.location.href,
                redirect_url: isApp
                  ? process.env.REACT_APP_HOME_EMPRESTIMO
                  : process.env.REACT_APP_HOME_SIMULADOR_NOVO,
                step: 'error',
              })

              window.location.href = isApp
                ? process.env.REACT_APP_HOME_EMPRESTIMO || ''
                : process.env.REACT_APP_HOME_SIMULADOR_NOVO || ''
            }}
          >
            Voltar pra Home
          </Button>
        </>
      }
    >
      <div style={{ justifyContent: 'center' }}>
        <GridStyled justify="space-around" margin="24px 0">
          <Timer />
        </GridStyled>
        <ColorWrapper
          fontWeight="600"
          style={{ marginBottom: '8px', display: 'flex', justifyContent: 'center' }}
        >
          <TextParagraph variant="headline-h1" textAlign="center">
            Agora a contratação é via app da CTPS
          </TextParagraph>
        </ColorWrapper>
        <ColorWrapper color={Colors.GRAY500} style={{ display: 'flex', justifyContent: 'center' }}>
          <TextParagraph variant="body-3" margin="10px 0 16px 0" textAlign="center">
            O novo sistema de crédito consignado para os trabalhadores CLT é uma iniciativa do
            Governo Federal e pode ser contratado diretamente do app da Carteira de Trabalho
            Digital.
          </TextParagraph>
        </ColorWrapper>
      </div>
    </NovoPage>
  )
}
export default Bloqueado
