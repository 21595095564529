/**
 * Actions Types
 */

import { HttpStatus } from '@services/base'

export enum IndiqueGanheTypes {
  SET_INDIQUE_GANHE = '@novo/indiqueGanhe/SET_INDIQUE_GANHE',
  SET_INDIQUE_GANHE_SUCCESS = '@novo/indiqueGanhe/SET_INDIQUE_GANHE_SUCCESS',
  SET_INDIQUE_GANHE_ERROR = '@novo/indiqueGanhe/SET_INDIQUE_GANHE_ERROR',
  SET_LOADING_INDIQUE_GANHE = '@novo/indiqueGanhe/SET_LOADING_INDIQUE_GANHE',
  RESET = 'RESET',
}

/**
 * State Type
 */

export interface IndiqueGanheState {
  readonly indiqueGanhe: HttpStatus
}

export interface indiqueGanhePayload {
  callback: (referralCode: string, cpf?: string) => void
}

export type StartPayload = {
  callback: () => void
  callbackError?: () => void
}
