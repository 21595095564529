import { Reducer } from 'redux'

import { HttpStatus } from '@services/base'

import { IndiqueGanheState, IndiqueGanheTypes } from './types'

const INITIAL_STATE: IndiqueGanheState = {
  indiqueGanhe: HttpStatus.NOT_FOUND,
}

const reducer: Reducer<IndiqueGanheState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IndiqueGanheTypes.SET_INDIQUE_GANHE:
      return { ...state, indiqueGanhe: action.payload }

    case IndiqueGanheTypes.RESET:
      return { ...INITIAL_STATE }

    default:
      return state
  }
}

export default reducer
