import { action, Action } from 'typesafe-actions'

import { IndiqueGanheTypes } from './types'

export const setIndiqueGanhe = (payload: string): Action =>
  action(IndiqueGanheTypes.SET_INDIQUE_GANHE, payload)

export const IndiqueGanheSuccess = (payload: string): Action =>
  action(IndiqueGanheTypes.SET_INDIQUE_GANHE_SUCCESS, payload)

export const IndiqueGanheError = (payload: string): Action =>
  action(IndiqueGanheTypes.SET_INDIQUE_GANHE_ERROR, payload)

export const setLoadingIndiqueGanhe = (payload: boolean): Action =>
  action(IndiqueGanheTypes.SET_LOADING_INDIQUE_GANHE, payload)
